import React, { useState, useCallback, useEffect } from "react";
import { checkProductType } from "../../lib/utils/checkProductType";
import ProductImage from "../common/ProductImage";
import BuyButton from "../ProductsListing/BuyButton";

const Search = ({searchModal, searchData, setsearchText, handleCancel, focusInput}) => {

    const [text, settext] = useState('');
    const [searchTextadd, setsearchTextadd] = useState(false);

    const calledSearchText = (value) => {            

        settext(value)

        if(value.length>2){
            setsearchTextadd(true);
            setsearchText(value);       
        }else{
            setsearchTextadd(false);
            setsearchText("");       
        }
    }
    
    const escFunction = (event) => {  
        if (event.key === "Escape") {            
            handleCancel();
        }                
    };

    useEffect(() => {
        
        document.querySelector("#normalSearch").value = "";
        document.querySelector("#mobileSearch").value = "";
        setsearchText("");
        settext("");
        setsearchTextadd(false);
        
        document.addEventListener("keydown", escFunction, false);
        document.addEventListener("mouseup", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
            document.removeEventListener("mouseup", escFunction, false);
        };
    }, [searchModal]);

  return (   
    <div className={searchModal?"container main-container search-outer-container showSearch":"search-outer-container container main-container"}>
        <div className="search-wrapper">
            <div className="search-tab-mob">
                <div className="search-triangle-black"></div>
                <div className="container-fluid">
                    <div className="header-row">
                        <div className="row">
                            <div className="col-10">
                                <input
                                id ="mobileSearch"
                                className="search-resp-input"
                                type="text"                                  
                                onChange = {(e) => calledSearchText(e.target.value)}                    
                                ref={focusInput}/>
                            </div>
                            <div className="col-2">
                                <button className="btn2" > Buscar </button>
                            </div>
                        </div>
                    </div>
                    {searchTextadd && 
                    <div className="row">
                        <div className="col-7">
                            <div className="list-bar">
                                <h6>Sugerencias</h6>
                                <ul>
                                    {searchData?.novedades == 1 && 
                                        <li>
                                            <a href={"/novedades?q=" + text}> <label>{text} en <strong>novedades</strong></label></a>
                                        </li>
                                    }
                                    {searchData?.catalogo == 1 && 
                                        <li>
                                            <a href={"/catalogo?q=" + text}>  <label>{text} en <strong>catálogo</strong></label></a>
                                        </li>
                                    }
                                    {searchData?.merchandise == 1 && 
                                        <li>
                                            <a href={"/merchandise?q=" + text}> <label>{text} en <strong>merchandise</strong></label></a>
                                        </li>
                                    }
                                    {searchData?.actualidad == 1 && 
                                        <li>
                                            <a href={"/noticias?q=" + text}>  <label>{text} en <strong>actualidad</strong></label></a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="search-container">
                <div className="white-header-row">
                    <div className="row">
                        <div className="col-12">
                            <input
                             id ="normalSearch"
                            className="search-resp-input"
                            type="text"                
                            onChange={(event) => calledSearchText(event.target.value)}
                            ref={focusInput}/>
                            <a id="myBtn2" href="#"><i className="large material-icons box5 forhide">search</i></a><i className="large material-icons box10 forhide" onClick={handleCancel}>close</i>
                        </div>
                    </div>
                </div>
                {searchTextadd && 
                <>
                    <div className="search-triangle"></div>
                    <div className="row main-row">
                        <div className="col-3">
                            <div className="left-bar">
                                <h6>Sugerencias</h6>
                                <ul>
                                    {searchData?.novedades == 1 && 
                                        <li>
                                            <a href={"/novedades?q=" + text}> <label>{text} en <strong>novedades</strong></label></a>
                                        </li>
                                    }
                                    {searchData?.catalogo == 1 && 
                                        <li>
                                            <a href={"/catalogo?q=" + text}>  <label>{text} en <strong>catalogo</strong></label></a>
                                        </li>
                                    }
                                    {searchData?.merchandise == 1 && 
                                        <li>
                                            <a href={"/merchandise?q=" + text}> <label>{text} en <strong>merchandise</strong></label></a>
                                        </li>
                                    }
                                    {searchData?.actualidad == 1 && 
                                        <li>
                                            <a href={"/noticias?q=" + text}>  <label>{text} en <strong>actualidad</strong></label></a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="right-bar product">
                                <h6>Productos destacados</h6>
                                <div className="cont-rht">
                                    <div className="row">
                                        {searchData?.data?.map((val, i) =>
                                        <div className="col-2" key={`pd${i}`}>
                                            <div className="img-wrapper scale-in">                            
                                                <ProductImage slug={`/${checkProductType(val.type)}/${val.slug}`} imageName={val.image} layout="fill"></ProductImage>
                                            </div>

                                            <h2>{val.title}</h2>
                                            {val.unitDiscountAmount != null ? <><span>{val.price}€</span> <label className="blue-text">{val.price}€</label></>: <label className="nrml-text">{val.price}€</label>}
                                            <BuyButton available={val?.available} productId={val?.id} stockAvailable={val?.stockAvailable}></BuyButton>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </>}
            </div>
        </div>
    </div>    
  )
};

export default Search;
