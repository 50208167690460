import { selector } from "recoil";
import { orderState, userState, sectionsState } from "../atoms/store";

export const ordersdata = selector({
  key: "orders", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const order = get(orderState);
    return order;
  },
});

export const userData = selector({
  key: "users", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const order = get(userState);
    return order;
  },
});

export const sectionsData = selector({
    key: "sections", // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
      const sections = get(sectionsState);
      return sections;
    },
  });
