import { atom } from "recoil";

export const orderState = atom({
  key: "orderstate", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});
export const userState = atom({
  key: "userstate", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});
export const sectionsState = atom({
    key: "sectionsstate", // unique ID (with respect to other atoms/selectors)
    default: "", // default value (aka initial value)
  });
