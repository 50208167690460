import getRootUrl from './getRootUrl';
import { setCookies, getCookies, removeCookies } from 'cookies-next';

export default async function sendRequest(path, opts = {}) {
 
    let authToken = getCookies('eccauthToken')?.eccauthToken ? getCookies('eccauthToken')?.eccauthToken : '';
    let shopId= "1"
    let language = "es"

    if (opts.authToken) {
        authToken = opts.authToken
    }    
    
    if (opts?.url){
        if(opts.url.includes("foreign-rights")) {
            shopId =  '8' ;
            language = 'en';
        }
    }
    if(opts?.xShopId){
        shopId = opts.xShopId
    }     
    
    const headers = Object.assign({}, opts.headers || {}, {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + authToken,
        'X-ShopId' : shopId,
        'X-Lang': language
    });

    const response = await fetch(
        `${getRootUrl()}${path}`,
        Object.assign({ method: 'POST', credentials: 'same-origin' }, opts, { headers }),
    );
    
    
    if (!response.ok) {
        
        if (response.status === 401 || response.status === 110) {
            removeCookies('ecc_auth_email');
            removeCookies('eccauthToken');
            window.location.href='/auth/login';
        }
        
        /*Here we should use sentry!!! */
        
        let message = `An error has occured: ${response.status}`; 
                 
        
            message += ' ' + path
        
        console.error('error', message)
        throw new Error();
    }else{
        const resolved = await response.json();
        
        if (resolved?.responseCode === 401){
            removeCookies('ecc_auth_email');
            removeCookies('eccauthToken');
            return;
        }
        return resolved
    }        
}