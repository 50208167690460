import { useEffect } from 'react';
import { orderState } from '../../atoms/store'
import { useSetRecoilState } from "recoil";
import { getShoppingCart} from '../../lib/api/cart.jsx';
import { getCartUUID } from '../../lib/utils/cart';

const CartManager = () => {
    const setOrders = useSetRecoilState(orderState);

    useEffect(() => {
        const _fetchCartObject = async () => {
            const cartUUID = getCartUUID();
            const resp = await getShoppingCart(cartUUID);
            if(resp.success) {
                setOrders(resp.data);
            }else{
                setOrders(null);
            }
        }

        _fetchCartObject();

      }, [])

    return (<></>)


}

export default CartManager;
