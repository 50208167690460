import React, { useEffect, useRef, useState } from "react";
import Link from 'next/link';
import Image from 'next/image';
import { reactRouterV3Instrumentation } from "@sentry/react";

const ProductImage = (props) => {
    /*const [imageNameSRC,setImageNameSRC] = useState(null)
    const arrSizes  = ['130','135','160','190','350']
    const [indexSize, setIndexSize] = useState(arrSizes.length-1);
    const [isError,setIsError] = useState(false)
    const [width, setWidth] = useState(0); // default width, detect on server.*/
    const [imageName, setImageName] =  useState(process.env.NEXT_PUBLIC_IMAGE_PATH + props.imageName.replace('.jpg','_350.jpg'))
    const [errorCount, setErrorCount] =  useState(0);

    useEffect(() => {
        if(props.imageName){
            setImageName(process.env.NEXT_PUBLIC_IMAGE_PATH + props.imageName.replace('.jpg','_350.jpg'))
        }                
    },[props.imageName])

    const handleError = () => {
        if (errorCount > 1) return;

        if(errorCount==0){
            setImageName(process.env.NEXT_PUBLIC_IMAGE_PATH + props.imageName.replace('.jpg','_156.jpg')) 
        }else{
            setImageName(process.env.NEXT_PUBLIC_IMAGE_PATH + '/web-assets/Imagen_no_disponible.jpg') 
        }

        setErrorCount(errorCount+1)
    }
    
    return (
        imageName && 
        <>
            {!props.imgTag && <Link href={props.slug} passHref={true}><a><Image quality={100} layout="fill" src={imageName} alt="" {...props} onError={handleError}></Image></a></Link>}
            {props.imgTag && <Link href={props.slug} passHref={true}><a><img  src={imageName} alt="" {...props} onError={handleError}></img></a></Link>}                
        </>
    );   
}

export default ProductImage
